.hide{
    display: none;
}

.disclaimer-component{
    background-color: rgb(214, 214, 214);
    padding: 3rem 7%;
    position: relative;

    .close-btn{
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        position: absolute;
        right: 2rem;
        top: 2rem;

        img{
            width: 1rem;
            opacity: 0.7;
        }
    }

    h2{
        text-transform: uppercase;
        color: #919191;
        font-size: 1rem;
        font-weight: 600;
    }

    p{
        font-weight: 400;
        font-size: .8rem;
        color: #444444;
        text-align: justify;

        a{
            color: #444444;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}