@import "./variables.scss";

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

html {
  scroll-behavior: smooth;
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: $font;
  background-color: $primary-color;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

button{
  border: none;
  outline: none;
}

.btn-gral {
  background-color: $highlight-color;
  border-radius: 1.2rem;
  text-transform: uppercase;
  padding: 1rem 1.6rem;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  user-select: none;
  transition: .1s ease-in-out all;
  text-decoration: none;
  color: #FFF;
  font-family: $secondary-font;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0,0,0, 0.2);


  img{
    height: .8rem;
    margin-left: 1rem;
  }

  &:hover {
    background-color: rgba($highlight-color, 0.8);
  }
}

.title-section{
  font-size: 4.5vw;
  text-align: center;
  margin-top: 0;
}

.title-page{
  font-size: 4.5vw;
  font-weight: 600;
  width: 70%;
  margin: 0 0 2vw 0;
  line-height: 120%;
}

.subtitle-page{
  font-size: 1.25vw;
  margin-top: 0;
  line-height: 140%;
  text-align: justify;
}

.input-line{
  display: flex;

  input, select{
    border: none;
    flex: 1;
    background-color: $light-background;
    outline: none;
    padding: .9rem 2rem;
    font-family: $font;
    border-radius: 1.2rem;
    font-size: 1rem;
    margin-right: 2rem;
    font-family: $font;
    margin-bottom: 2rem;
    min-width: 5rem;
    text-transform: uppercase;

    &::placeholder{
      color: #7f7f8b;
      font-family: $font;
      text-transform: uppercase;
      font-size: 1rem;
    }

    &:last-child{
      margin-right: 0;
    }

    &.error{
      border: 2px solid rgb(230, 115, 115);
    }
  }

  select{
    appearance: none;
    color: #7f7f8b;
    -moz-appearance: none;
  }

  .custom-select{
    width: 100%;
    position: relative;

    &::after{
      content: '';
      width: 2rem;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url("/assets/img/flecha_abajo.svg");
      background-size: .85rem;
      background-repeat: no-repeat;
      background-position-x: left;
      background-position-y: 1.1rem;
    }

    select{
      width: 100%;
    }
  }
}

.link {
  color: rgba($highlight-color, 0.8);
}

@media(max-width: 992px) {
  * {
    cursor: default !important;

    &:focus {
      outline: none;
    }
  }

  .title-section{
    font-size: 1.7rem;
  }

  .title-page{
    font-size: 2.5rem;
  }

  .subtitle-page{
    font-size: .9rem;
  }

  .input-line{
    flex-direction: column;
    width: 100%;

    input,
    select{
      margin-right: 0;
      width: 100%;
      font-size: .9rem;
      padding-left: 1.2rem;

      &::placeholder{
        font-size: .9rem;
      }
    }

    .custom-select{
      &::after{
        background-size: .7rem;
        background-position-x: center;
      }
    }
  }
}
