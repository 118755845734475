.portfolio-page{
    background-color: white;

    .portfolio-content{
        padding: 0 7% 7rem 7%;

        .portfolio-title-section{
            margin: 0 auto;
            padding: 15vh 0;
            width: 60%;

            h2, p{
                text-align: justify;
                text-align-last: center;
                width: 100%;
            }
        }

        .custom-table{
            .row{
                width: 100%;
                display: flex;
                padding: 1.4rem 1rem;
                border-bottom: 1px solid #000;
                align-items: center;

                &:hover{
                    background-color: rgb(245, 245, 245);
                }

                &.head{
                    color: gray;
                    text-transform: uppercase;

                    &:hover{
                        background-color: transparent;
                    }
                }

                span{
                    flex: 1;
                    display: flex;
                    align-items: center;

                    img{
                        height: 1.5rem;
                        margin-right: .5rem;
                    }
                }
            }
        }
    }
}

@media(max-width: 992px){
    .portfolio-page {
      .portfolio-content {

        .portfolio-title-section{
            width: 90%;

            h2{
                margin-bottom: 1.5rem;
                text-align: center;
            }
        }

        .custom-table {
          .row {
            span {
                font-size: .85rem;
            }
          }
        }
      }
    }
}
