@import "../../variables";

.arrow-down {
  height: .9rem;
  margin-left: 5px;
  margin-top: 3px;
}

.arrow-down2 {
  height: .9rem;
  position: relative;
  top:20px;
  right:30px;
}

.header-container {
  color: #000;
  background-color: white;
  font-family: $secondary-font;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7%;
  height: 6rem;

  .menu-mobile-btn,
  .background-menu-mobile {
    display: none;
  }

  .logo{
    height: 100%;
    width: 6rem;

    a{
      text-decoration: none;

      svg.logo-svg{
        height: 100%;

        &:hover{
          .cls-1{
            fill: $highlight-color;
          }
        }

        .cls-1 {
          fill: #00153D;
        }

        .cls-2 {
          fill: #fff;
        }
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      margin: 0 2rem;
      font-size: 1.3rem;
      text-transform: uppercase;
      user-select: none;
      transition: .1s ease-in-out all;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: .2s ease-in-out all;
      position: relative;

      a {
        text-decoration: none;
        color: inherit;

        &::before{
          height: 2px;
          background-color: $highlight-color;
          position: absolute;
          left: 0;
          bottom: -9px;
          content: '';
          width: 0;
          transition: .2s ease-in-out all;
        }

        &:hover, &.active {
          color: $highlight-color;

          &::before{
            width: 100%;
          }
        }
      }

      &.dropdown-container {
        position: relative;

        a::before{
          content: none;
        }

        &:hover{
          opacity: 1;
          color: #000;

          .sub-menu{
            display: block;
          }
        }

        .sub-menu {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 1rem;
          padding-top: 1.2rem;
          text-transform: capitalize;
          transition: all .3s ease-in-out;
          display: none;

          a{
            background-color: rgb(0, 0, 0);
            color: #FFF;
            display: block;
            padding: 1.2rem 1rem;
            white-space: nowrap;
            font-size: 1rem;
            text-align: center;
            border: 1px solid rgba(255, 255, 255, 0.1);
            transition: all .15s ease-in-out;
            opacity: 0;
            animation: fadeIn .3s 0s ease-in forwards;
            text-decoration: none;

            @for $i from 1 through 5 {
              &:nth-child(#{$i}n) {
                animation-delay: #{$i * 0.07}s;
              }
            }

            &:hover{
              background-color: #161f32;
            }
          }
        }

        .button-language{
          background-color: rgb(0, 0, 0);
          color: #FFF;
          display: block;
          padding: 1.2rem 1rem;
          margin-bottom: 0.2rem;
          white-space: nowrap;
          font-size: 0.7rem;
          text-align: center;
          border: 1px solid rgba(255, 255, 255, 0.1);
          transition: all .15s ease-in-out;
          opacity: 0;
          animation: fadeIn .3s 0s ease-in forwards;
          text-decoration: none;

          @for $i from 1 through 5 {
            &:nth-child(#{$i}n) {
              animation-delay: #{$i * 0.07}s;
            }
          }

          &:hover{
            background-color: #161f32;
          }
        }


      }
    }
  }

  .right-box {
    display: flex;
    align-items: center;

    .btn-gral {
      margin-right: 2rem;
      background-color: $highlight-color;

      &:hover{
        background-color: rgba($highlight-color, 0.8);
      }
    }

    .language {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      cursor: pointer;
    }
  }

}

.world-icon {
  margin-right: 10px;
  height: 2rem;
  width: 2rem;

}

@keyframes fadeIn {
  0%{
    opacity: 0;
    transform: translateY(.5rem);
  }
  100%{
    opacity: 1;
    transform: translateY(0rem);
  }
}

@media(max-width: 1500px) {
  .arrow-down {
    height: .75rem;
  }

  .header-container {
    ul {
      li {
        font-size: 1.1rem;

        &.dropdown-container{
          .sub-menu{
            span{
              font-size: .9rem;
            }
          }
        }
      }
    }

    .right-box {
      .language {
        font-size: 1.2rem;
      }
    }
  }
}

@media(max-width: 992px) {
  .header-container {
    position: relative;
    height: 4rem;
    padding: 0 1.5rem;

    .menu-mobile-btn{
      display: block;
      position: fixed;
      right: .5rem;
      top: .5rem;
      background-color: $primary-color;
      padding: .6rem;
      z-index: 2;
      border-radius: 5px;

      img{
        width: 1.5rem;
      }
    }

    .background-menu-mobile{
      width: 100vw;
      height: 100vh;
      background-color: transparent;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 2;

      &.active-menu{
        display: block;
      }
    }

    ul {
      position: fixed;
      left: 0;
      top: 0;
      flex-direction: column;
      width: 80vw;
      height: 100vh;
      background: black;
      margin: 0;
      padding: 1rem 0 0 0;
      transition: all .4s ease-in-out;
      z-index: 2;
      transform: translateX(-80vw);

      li{
        padding: 1.5rem 0;
        width: 100%;
        justify-content: center;
        margin: 0;
        border-bottom: 1px solid rgb(32, 32, 32);
        color: white;

        a{
          &::before{
            bottom: 0px;
          }

        }

        &.dropdown-container{
          flex-wrap: wrap;

          *{transition: all .3s ease-in-out;}

          &:hover{
            padding-bottom: 0;
            color: #FFF;

            .sub-menu{
              display: flex;
              justify-content: flex-end;
              flex-direction: column;            }
          }

          .sub-menu{
            position: initial;
            transform: none;
            width: 100%;

            a{
              padding: .5rem;
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }

      }

      .button-language{
        background-color: rgb(216, 15, 15);
        color: #FFF;
        display: block;
        padding: 1.2rem 1rem;
        margin-bottom: 0.2rem;
        white-space: nowrap;
        font-size: 0.7rem;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 0.1);
        transition: all .15s ease-in-out;
        opacity: 0;
        animation: fadeIn .3s 0s ease-in forwards;
        text-decoration: none;

        @for $i from 1 through 5 {
          &:nth-child(#{$i}n) {
            animation-delay: #{$i * 0.07}s;
          }
        }

        &:hover{
          background-color: #161f32;
        }
      }

    }

    .right-box {
      position: fixed;
      left: 0;
      bottom: 2rem;
      width: 80vw;
      z-index: 3;
      flex-direction: column-reverse;
      transition: all .4s ease-in-out;
      transform: translateX(-80vw);

      .language {
        font-size: 1rem;

        .world-icon {
          height: 1rem;
          margin-right: 3px;
        }
      }

      .btn-gral {
        padding: .7rem 1rem;
        font-size: 1rem;
        margin-right: 0;
        margin-top: 2rem;
        width: 90%;
        justify-content: center;
      }
    }

    .active-menu{
      transform: translateX(0);
    }
  }
}

@media(max-width: 330px){
  .header-container ul li{
    padding: 1.1rem 0;

    &.dropdown-container .sub-menu {
      padding-top: 1.1rem;
    }
  }
}
