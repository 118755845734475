@import "../../variables";

.blockchain-notice{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 7%;
    background-color: $light-background;

    .text-content{
        h2{
            font-size: 1.9vw;
            margin: 0;
        }

        p{
            margin: .5rem 0 0 0;
            font-size: 1vw;
        }
    }

    .btn-gral{
        padding: 1rem 4rem;
        font-size: 1.3rem;
        box-shadow: $shadow;    
        
        &:hover{
            opacity: .8;
        }
    }
}

@media(max-width: 992px){
    .blockchain-notice{
        flex-direction: column;
        padding: 2rem 1.5rem;

        .text-content{
            h2{
                font-size: 1.2rem;
                text-align: center;
            }

            p{
                text-align: center;
                font-size: .9rem;
            }
        }

        .btn-gral{
            margin-top: 1.5rem;
            width: 100%;
            justify-content: center;
            font-size: 1.1rem;
        }
    }
}