.welcome-component{
    padding: 2rem 7%;
    color: #000;
    background-color: white;
    height: calc(100vh - 6rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2{
        font-size: 5.5vw;
        font-weight: 600;
        width: 70%;
        margin: 0 0 2vw 0;
        line-height: 120%;
    }
    p{
        font-size: 1.6vw;
        width: 50%;
        margin-top: 0;
        line-height: 120%;
    }
}

@media(max-width: 992px){
    .welcome-component{
        height: calc(100vh - 4rem);
        padding: 2rem 1.5rem;

        h2{
            font-size: 2.7rem;
            width: 100%;
        }

        p{
            font-size: 1.3rem;
            width: 100%;
        }
    }
}
