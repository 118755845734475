.aboutus-page{
    background-color: white;

    .aboutus-content{
        padding: 0 7% 10rem 7%;

        .aboutus-title-section{
            margin: 0 auto;
            padding: 15vh 0;
            width: 50%;

            h2,
            p {
              text-align: center;
              width: 100%;
            }

            p{
                text-align: justify;
                text-align-last: center;
            }
        }

        .social-media{
            display: flex;

            & > div{
                flex: 1;
                margin-right: 20%;

                &:last-child{
                    margin-right: 0;
                }

                h3{
                    font-size: 1.5rem;
                    padding-bottom: .5rem;
                    border-bottom: 1px solid;
                    font-weight: 600;
                }

                a{
                    display: flex;
                    align-items: center;
                    color: #000;
                    text-decoration: none;

                    img{
                        width: 1.8rem;
                        margin-right: .5rem;
                    }
                }
            }
        }
    }
}

@media(max-width: 992px){
    .aboutus-page {
      .aboutus-content {
        .aboutus-title-section {
            width: 100%;

          p {
            font-size: .9rem;
            margin-top: 2rem;
          }
        }

        .social-media {
            flex-direction: column;

          &>div {
              margin-bottom: 2rem;
              margin-right: 0;

            a {
              img {
                width: 1.5rem;
              }
            }
          }
        }
      }
    }
}
