@import "../../variables";

.types-info-section{
    background-color: white;
    color: #000;
    padding: 6% 7%;

    .btn-gral{
        display: block;
        margin: 0 auto;
        width: max-content;
        font-size: 1.3rem;
        box-shadow: $shadow;        
    }

    .types-box{
        display: flex;
        margin-top: 5rem;

        & > div {
            flex: 1;
                padding: 4% 0;
                margin-right: 7%;

            &:last-child{
                margin-right: 0;
            }

            &:hover{
                .icon-type{
                    transform: scale(1.1);
                }

                h4{
                    color: $highlight-color;
                }
            }

            .icon-type{
                width: 30%;
                margin: 0 auto 3vw auto;
                display: block;
                transition: .3s ease-in-out all;
            }

            h4{
                margin-top: 0;
                margin-bottom: 1rem;
                text-transform: uppercase;
                font-size: 1.3vw;
                text-align: center;
                border-bottom: 1px solid #000;
                padding-bottom: 1rem;
                transition: .2s ease-in-out all;
                cursor: default;
            }

            p{
                line-height: 170%;
                font-size: 0.86vw;
                margin-top: 0;
                text-align: justify;
                text-align-last: center;
                cursor: default;
            }
        }
    }
}

@media(max-width: 1500px){
    .types-info-section{

        .btn-gral{
            font-size: 1.1rem;
        }

        .types-box{

            & > div{

                h4{
                    font-size: 1.4rem;
                }
                
                p{
                    font-size: .9rem;
                }
            }
        }
    }
}

@media(max-width: 992px){
    .types-info-section{
        padding: 4rem 1.5rem 2rem 1.5rem;

        .btn-gral{
            font-size: 1.15rem;
        }

        .types-box{
            flex-direction: column;

            & > div{
                margin: 0 0 2rem 0;
                padding: 1.2rem;

                .icon-type{
                    width: 6rem;
                    margin-bottom: 3rem;
                }

                h4{
                    font-size: 1.2rem;
                }

                p{
                    font-size: .9rem;
                }
            }
        }
    }
}