@import "../../variables";

.opportunities-section{
    background-color: $light-background;
    display: flex;
    flex-direction: column;
    padding: 6% 7%;
    min-height: 70vh;

    .left-content {

        h3{
            font-size: 2.5vw;
            width: 100%;
            margin-top: 2rem;
            line-height: 130%;
            text-align: center;
        }
    }

    .opportunities-box {
        margin-top: 3rem;

        ul{
            list-style: none;
            padding: 0;
            display: flex;

            li{
                width: 100%;
                padding: 2rem;
                font-size: 1.1rem;
                line-height: 170%;
                flex: 1;
                margin-right: 7%;
                border: solid #000;
                border-width: 1px 0px;
                transition: all ease-in-out .3s;
                position: relative;
                cursor: default;
                text-align: justify;
                text-align-last: center;

                &:last-child{
                    margin-right: 0;
                }

                &:hover{
                    border-color: transparent;     

                    &::before, &::after{
                        width: 100%;
                    }
                }

                &::before, &::after{
                    height: 2px;
                    width: 0%;
                    background-color: $highlight-color;
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    transition: .3s ease-in-out all;
                }

                &::after{
                    left: initial;
                    top: initial;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}

@media(max-width: 1500px){
    .opportunities-section {
        .opportunities-box ul li{
            font-size: .9rem;
            margin-right: 4%;
        }
    }
}

@media(max-width: 992px){
    .opportunities-section{
        padding: 4rem 1.5rem 6% 1.5rem;
        
        .opportunities-box{
            ul{
                flex-direction: column;
                
                li{
                    border-width: 0px 1px;            
                    margin: 0 0 3rem 0;
                }
            }
        }
    }
}
