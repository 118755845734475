@import "../../variables";

.footer {
  .content {
    display: flex;
    color: #FFF;
    padding: 6rem 7%;
    background-color: $primary-color;

    .left-content {
      flex: 1;

      .logo {
        img{
          width: 50%
        }
      }

      .social-media {
        margin-top: 1.5rem;

        a {
          color: white;
          text-decoration: none;
          font-size: 1.2rem;
          display: flex;
          font-family: $font;
          align-items: center;
          font-weight: 500;

          img {
            height: 1.8rem;
            margin-right: .5rem;
          }
        }
      }
    }

    .right-content {
      flex: 1;
      font-family: $font;
      display: flex;
      justify-content: flex-end;

      ul {
        list-style: none;
        padding-left: 8vw;
        width: max-content;

        li {
          font-family: $secondary-font;
          text-transform: uppercase;
          font-size: 1.3rem;
          margin-bottom: 1rem;

          &.sub-li {
            text-transform: initial;
            opacity: .6;
            cursor: pointer;
          }

          a {
            text-decoration: none;
            color: inherit;

            &:hover {
              opacity: .7;
            }
          }

        }
      }
    }
  }

  .subfooter {
    background-color: $extra-color;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 7%;
    
    .address{
      color: rgba(255, 255, 255, 0.8);
      font-size: 0.9rem;
      text-align: center;
    }

    .language {
      font-family: $secondary-font;
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      cursor: pointer;

      .world-icon {
        margin-right: 10px;
        height: 1.3rem;
      }
    }
  }
}

@media(max-width: 1500px) {
  .footer {
    .content {
      .right-content {
        ul {
          li {
            font-size: 1.1rem;
          }
        }
      }
    }

    .subfooter {
      .language {
        font-size: 1.2rem;
      }
    }
  }
}

@media(max-width: 992px) {
  .footer {
    .content {
      flex-direction: column;
      padding: 3rem 1.5rem;

      .right-content {
        padding: 0;
        margin-top: 2rem;
        justify-content: flex-start;

        ul {
          padding-left: 0;

          &:nth-child(2){
            padding-left: 3rem;
          }

          li {
            font-size: .95rem;
          }
        }
      }
    }

    .subfooter {
      padding: 2rem 1.5rem;
      font-size: .8rem;
      flex-direction: column;
      justify-content: flex-start;

      .address{
        margin: 1rem 0;
        text-align: left;
        display: block;
        font-size: .8rem;
      }
    }
  }
}
