.invest-component{
    background-color: white;

    .invest-content{
        padding: 14vh 7% 8rem 7%;
        display: flex;

        .invest-left-box{
            width: 45%;
            padding-right: 10%;

            .subtitle-page{
                text-align: justify;
            }
        }

        .invest-right-box {
            width: 55%;
            .message-error{
              text-align: center;
              color: red;
            }
            .message-success{
              text-align: center;
              color: #0c0;
            }

            .submit {
                width: 100%;
                justify-content: center;
            }
        }
        .social-media{
          display:flex;

          & > div{
            position:relative;
            top:120px;
            flex: 1;
            margin-right: 20%;

            &:last-child{
                margin-right: 0;
            }

            h3{
                font-size: 1.5rem;
                padding-bottom: .5rem;
                border-bottom: 1px solid;
                font-weight: 600;

            }

            a{
                display: flex;
                align-items: center;
                color: #000;
                text-decoration: none;

            }
          }

        }
    }

    .btn-gral{
      width: 100%;
      background-color: #FF0054;
      justify-content: center;
      &.disabled {
        pointer-events: none;
        background-color: gray;
      }
  }
}

@media(max-width:992px){
    .invest-component{
        .invest-content{
            flex-direction: column;

            .invest-left-box{
                margin-bottom: 2rem;
                width: 100%;
                padding-right: 0;

                .title-page{
                    text-align: center;
                    width: 100%;
                }
            }



            .invest-right-box{
                width: 100%;
            }

        }
    }

}
