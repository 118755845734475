@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800&display=swap');

$font: 'Heebo', sans-serif;
$secondary-font: 'Ubuntu Mono', monospace;

$primary-color: #00153D;
$secondary-color: #3254EC;
$light-background: #F2F2FF;
$highlight-color: #FF0054;
$extra-color: #001130;
$shadow: 0px 4px 20px #dddde7;
