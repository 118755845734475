@import "../../variables";

.funds-overview-content {
  background-color: white;
  padding: 15vh 7% 10rem 7%;

  .title-page,
  .subtitle-page {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 5vh;
  }

  .subtitle-page{
    text-align: justify;
    text-align-last: center;
  }

  .title-page {
    text-align: justify;
    text-align-last: center;
    margin-bottom: 2.5rem;
  }

  .funds-table {
    margin-top: 20vh;

    .row {
      width: 100%;
      border-bottom: 2px solid #000;
      padding: 2rem 1rem;

      &:first-child{
        border-top: 2px solid #000;
      }

      &:hover{
        color: $highlight-color;
      }

      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: center;
        width: 100%;

        .name {
          font-size: 1.6rem;
          text-transform: uppercase;
          font-weight: 600;
          width: 25%;
          white-space: nowrap;
          padding-right: 10%;
        }

        .details {
          flex: 1;
          padding-right: 9%;

          p {
            text-align: left;
            font-size: 1.2rem;
            margin: 0 0 0.4rem 0;

            b {
              font-weight: 600;
            }
          }
        }

        .descr {
          flex: 1;
          padding-right: 9%;
          font-size: 1.2rem;
          text-align: justify;
        }

        .arrow {
          width: max-content;

          img {
            width: 1.5rem;
          }
        }
      }
    }
  }
}

@media(max-width: 1500px){
  .funds-overview-content{
    .funds-table{
      .row{
        a{
          .name{
            padding-right: 7%;
            font-size: 1.3rem;
          }
          .details p, .descr{
            font-size: .9rem;
          }
        }
      }
    }
  }
}

@media(max-width: 992px){
  .funds-overview-content{
    .title-page,
    .subtitle-page{
      width: 100%;
    }

    .title-page{
      text-align: center;
    }

    .funds-table{
      overflow-x: scroll;

      .scroll-mobile{
        width: 1000px;

        .row{
          a{
            .name{
              font-size: 1.1rem;
            }
            .details p, .descr{
              font-size: .8rem;
            }
          }
        }
      }
    }
  }
}
