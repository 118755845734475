.submenu-funds-page{    
    .submenu-funds-content{
        background-color: white;
        padding: 15vh 7% 10rem 7%;
        display: flex;

        .left-box{
            width: 60%; 
            padding-right: 10%;  
            display: flex;
            flex-direction: column;
            justify-content: flex-end;   
            
            .title-page,
            .subtitle-page{
                width: 100%;
            }            
        }

        .right-box{
            width: 40%;

            h3{
                font-size: 1.6rem;
                width: 100%;
                border-bottom: 1px solid #000;
                font-weight: 600;
                padding-bottom: .5rem;
            }

            .table{
                padding-top: 2rem;

                .row{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 1.2rem;
                    padding-bottom: .8rem;
                    
                    span{
                        font-weight: 300;  
                        text-align: right                      
                    }

                    b{
                        font-weight: 400;
                    }
                }
            }

            .btn-gral{
                justify-content: center;
                margin-top: 3rem;
                font-size: 1.2rem;
            }
        }
    }
}

@media(max-width: 1500px){
    .submenu-funds-page{
        .submenu-funds-content{
            .left-box{
                width: 50%;
            }

            .right-box{
                width: 50%;

                h3{
                    font-size: 1.3rem;
                }

                .table{
                    .row{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media(max-width: 992px){
    .submenu-funds-page{
        .submenu-funds-content{
            flex-direction: column;

            .left-box{
                width: 100%;
                padding-right: 0;

                .title-page{
                    text-align: center;
                }

                .subtitle-page {
                  text-align: justify;
                  text-align-last: center;
                }
            }

            .right-box{
                width: 100%;
                margin-top: 3rem;

                h3{
                    font-size: 1.2rem;
                    text-align: center;
                }

                .table{
                    .row{
                        font-size: .8rem;

                        span, b{
                            max-width: 70%;
                        }
                    }
                }
            }
        }
    }
}
